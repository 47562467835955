<template>
    <div class="modal-backdrop">

        <div class="gestion-rapport">

            <header class="modal-header">
                <p>Liste des rapports</p>
                <button type="button" class="btn-close" @click="$emit('closeGestionRapportModel')">
                    x
                </button>
            </header>

            <section class="modal-body">

                <table>

                    <tr>
                        <th>index</th>
                        <th>Rapport</th>
                        <th>Staus</th>
                        <th>Responsable Client</th>
                        <th>Désignation</th>
                        <th>Date de Production</th>
                        <th>Date d'intervention</th>
                        <th>Type de Rapport</th>
                        <th>Equipement</th>
                        <th>Actions</th>
                    </tr>

                    <tr v-for="item in rapports" :key="item.id">

                        <td>
                            {{ item.id }}
                        </td>

                        <td>
                            <input type="text" @input="addRef($event, item.id)" v-model="item.ref"
                                :disabled="item.disabled">
                            <input type="hidden" v-model="item.referenceRapport">
                        </td>

                        <td>
                            {{ item.status == true ? "Envoye a client" : "Non Envoye" }}
                        </td>

                        <td>
                            <p v-if="item.disabled">Déja saisir</p>
                            <input v-else type="text" @input="addResponsableClient($event, item.id)">
                        </td>

                        <td>
                            <p v-if="item.disabled">Déja saisir</p>
                            <input v-else type="text" @input="addDesignation($event, item.id)">
                        </td>

                        <td>
                            <p v-if="item.disabled">Déja saisir</p>
                            <input v-else type="date" @input="addDateProductionControle($event, item.id)">
                        </td>

                        <td>
                            <p v-if="item.disabled">Déja saisir</p>
                            <input v-else type="date" @input="addDateIntervention($event, item.id)">
                        </td>

                        <td>
                            <p v-if="item.typeRapport">Déja saisir</p>
                            <select v-if="item.typeRapport != true" @change="addTypeRapport($event, item.id)">
                                <option :value="mission" v-for="mission in missions" :key="mission">{{ mission }}
                                </option>
                            </select>
                        </td>

                        <td>
                            <p v-if="item.equipement">Déja saisir</p>
                            <select v-if="item.equipement != true" @change="addEquipement($event, item.id)">
                                <option v-for="equipement in equipements" :value="equipement" :key="equipement">{{ equipement }}</option>
                            </select>
                        </td>

                        <td>
                            <button title="supprime le rapport" @click="supprimer(item.ref)">supprimer</button>
                            <input v-if="!item.disabled" type="file" multiple="multiple" placeholder="Fichier PDF"
                                ref="file" @change="onFileChange($event, item.id)">
                            <button v-if="item.disabled" title="change réfernce de rapport"
                                @click="changer(item.id)">Change</button>
                            <button v-if="!item.disabled" title="valider réfernce de rapport"
                                @click="valider(item.id)">Valider</button>
                        </td>

                    </tr>
                </table>

            </section>

            <footer class="modal-footer">
                <button type="button" class="btn-red" @click="$emit('closeGestionRapportModel')">
                    Fermer
                </button>
                <button type="button" class="btn-bleu" @click="ajouterRapport">
                    Ajouter Rapport
                </button>
            </footer>

        </div>

    </div>
</template>

<script>

import Service from "../../../../../Service"
import Rapport from "../../../../../Rapport"

export default {
    name: 'gestion-rapport',
    data() {
        return {
            rapports: [],
            listeRapports: [],
            countPreventListRapports: 0,
            codeMission: null,
            numeroAffaire: null,
            clientId: null,
            missionId: null,
            missions: [],
            equipements: [],
            missionsEquipements : []
        }
    },
    props: {
        affaireId: String
    },

    methods: {

        addRef(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].ref = event.target.value.replaceAll("/", "|");
        },

        addResponsableClient(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].responsableClient = event.target.value;
        },
        
        addDesignation(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].designation = event.target.value;
        },

        addDateProductionControle(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].dateProductionControle = event.target.value;
        },

        addDateIntervention(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].dateIntervention = event.target.value;
        },

        addTypeRapport(event, id) {

            this.equipements = [];
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].typeRapport = event.target.value;

            for(let i = 0; i < this.missionsEquipements.length; i++) {
                if(this.missionsEquipements[i].mission == this.rapports[index].typeRapport) {
                    if(!this.equipements.includes(this.missionsEquipements[i].equipement)) {
                        this.equipements.push(this.missionsEquipements[i].equipement)
                    }
                }
            }

            this.equipements.unshift("");
        },

        addEquipement(event, id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].equipement = event.target.value;
        },

        onFileChange(e, id) {
            this.rapports[id]["file"] = e.target.files[0];
        },

        valider(index) {

            const {
                clientId,
                dateIntervention,
                dateProductionControle,
                designation,
                equipement,
                file,
                ref,
                responsableClient,
                referenceRapport,
                typeRapport
            } = this.rapports[index];

            if (file == null) {

                Rapport.validerRapportByNotFile(responsableClient, designation, clientId, ref, typeRapport, dateProductionControle, dateIntervention, this.numeroAffaire, equipement, referenceRapport)
                    .then((result) => {
                        if (result.cas) {

                            this.rapports[index].referenceRapport = result.referenceRapport;
                            this.rapports[index].disabled = true;
                            this.rapports[index].status = false;
                            this.rapports[index].typeRapport = true;
                            this.rapports[index].equipement = true;

                            this.listeRapports.push({
                                        ref: result.referenceRapport,
                                        typeRapport: typeRapport,
                                        equipement: equipement
                            });

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }

            if (file != null) {

                Rapport.validerRapportByFile(file, responsableClient, designation, clientId, ref, typeRapport, dateProductionControle, dateIntervention, this.numeroAffaire, equipement, referenceRapport)
                    .then((result) => {
                        if (result.cas == true) {

                            this.rapports[index].referenceRapport = result.referenceRapport;
                            this.rapports[index].disabled = true;
                            this.rapports[index].status = true;
                            this.rapports[index].typeRapport = true;
                            this.rapports[index].equipement = true;

                            
                            this.listeRapports.push({
                                ref: result.referenceRapport,
                                typeRapport: typeRapport,
                                equipement: equipement
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }

        },

        supprimer(ref) {

            Rapport.deleteRapport(ref, this.numeroAffaire)
                .then((result) => {
                    if (result) {
                        const index = this.rapports.findIndex((el) => el.ref == ref);
                        this.rapports.splice(index, 1);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        changer(id) {
            const index = this.rapports.findIndex((el) => el.id == id);
            this.rapports[index].disabled = !this.rapports[index].disabled;
        },


        ajouterRapport() {

            this.rapports.push({
                id: this.rapports.length,
                ref: "",
                referenceRapport: "",
                status: false,
                disabled: false,
                file: null,
                responsableClient: "",
                designation: "",
                clientId: this.clientId,
                typeRapport: "",
                equipement: "",
                dateProductionControle: "",
                dateIntervention: ""
            });
        }
    },

    created() {

        Service.getAffaireById(this.affaireId)
            .then((result) => {

                this.numeroAffaire = result.data.affaire.numeroAffaire;
                this.clientId = result.data.affaire.clientId;

                for (let i = 0; i < result.data.affaire.refRapport.length; i++) {

                    this.rapports.push({
                        id: i,
                        ref: result.data.affaire.refRapport[i],
                        referenceRapport: result.data.affaire.refRapport[i],
                        status: result.data.affaire.sendRapport[i],
                        disabled: true,
                        file: null,
                        responsableClient: "",
                        designation: "",
                        clientId: this.clientId,
                        typeRapport: true,
                        equipement: true,
                        dateProductionControle: "",
                        dateIntervention: ""
                    });
                }


                // get missions liee a cette offre
                Service.getMission(this.numeroAffaire)
                    .then((result) => {
                        

                        for (let i = 0; i < result.data.missions.length; i++) {

                            for (let j = 0; j < result.data.missions[i].equipement.length; j++) {

                                for (let k = 0; k < result.data.missions[i].equipement[j].refRapport.length; k++) {

                                    this.listeRapports.push({
                                        ref: result.data.missions[i].equipement[j].refRapport[k],
                                        typeRapport: result.data.missions[i].typeMission,
                                        equipement: result.data.missions[i].equipement[j].titre
                                    });

                                }
                            }

                        }

                        for (let i = 0; i < result.data.missions.length; i++) {
                            if(!this.missions.includes(result.data.missions[i].typeMission)) {
                                this.missions.push(result.data.missions[i].typeMission);
                            }
                        }

                        this.missions.unshift("");

                        for (let i = 0; i < result.data.missions.length; i++) {
                            for(let j = 0; j < result.data.missions[i].equipement.length; j++) {
                                this.missionsEquipements.push({ 
                                    mission : result.data.missions[i].typeMission,
                                    equipement : result.data.missions[i].equipement[j].titre
                                });
                            }
                        }



                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
            });

    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;
}


.gestion-rapport {
    height: 1000px;
    width: 1400px;
    background-color: white;
    border-radius: 5px;
    padding: 0;
    margin: 0;
}

header {
    padding: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5%;
    background-color: rgb(27, 51, 192);
    color: white;
    align-items: center;
    justify-content: space-between;
}

header p {
    font-size: large;
}

header button {
    height: 100%;
    width: 40px;
    background-color: rgb(255, 0, 0);
    color: white;
    cursor: pointer;
}

header button:hover {
    background-color: rgba(255, 0, 0, 0.699);
}

section {
    overflow: scroll;
    height: 90%;
    margin: 0px;
    padding: 0px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

td input {
    width: 300px;
    height: 40px;
    padding: 5px;
    font-size: larger;
}

td:nth-child(6) input,
td:nth-child(7) input {
    width: 130px;
    height: 40px;
    padding: 5px;
    font-size: larger;
}

td select {
    width: 500px;
    height: 40px;
    padding: 5px;
    font-size: larger;
}

td button {
    height: 100%;
    width: 100px;
    color: white;
    margin: 3px;
    border: 0px;
    font-size: large;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

td button:nth-child(1) {
    background-color: red;
}

td button:nth-child(2) {
    background-color: rgb(8, 8, 168);
}

td button:nth-child(3) {
    background-color: green;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

footer {
    padding: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5%;
    background-color: rgb(255, 255, 255);
    color: white;
    align-items: center;
    justify-content: center;
}

footer button:nth-child(2) {
    height: 100%;
    width: 200px;
    background-color: green;
    color: white;
    margin: 3px;
    border: 0px;
    font-size: large;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

footer button:nth-child(1) {
    height: 100%;
    width: 100px;
    background-color: red;
    color: white;
    margin: 3px;
    border: 0px;
    font-size: large;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}
</style>