<template>
  <div class="all" style="width:100%;">
    <hr>
    <div class="form" v-if="flagMession">
        <div>
            <label for="Liste des missions">Mission</label>

            <select v-model="mission.typeMission" @change="selectMission($event)" :disabled ="flagDisabledMissions">
              
              <option value="">Sélectionnez votre mission</option>

              <optgroup label="Installations électriques">
                <option v-for="mission in ArrayTypeMissionIE" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Appareils et accessoires de levage">
                <option v-for="mission in ArrayTypeMissionAAL" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>


              <optgroup label="Installation d'ascenseur">
                <option v-for="mission in ArrayTypeMissionID" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Dispositif incendie">
                <option v-for="mission in ArrayTypeMissionDI" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Appareils à pression gaz et vapeur">
                <option v-for="mission in ArrayTypeMissionAPGV" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Contrôle machines et engins de chantiers">
                <option v-for="mission in ArrayTypeMissionCMEC" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Contrôles risques au travail">
                <option v-for="mission in ArrayTypeMissionCRT" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Environnement">
                <option v-for="mission in ArrayTypeMissionENV" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Formations réglementaires incendie">
                <option v-for="mission in ArrayTypeMissionI" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <optgroup label="Formations réglementaires electricité">
                <option v-for="mission in ArrayTypeMissionE" :key="mission.name" :value="mission.prev+'_'+mission.type+'_'+mission.name+'_'+mission.categorie"> {{ mission.name }}</option>
              </optgroup>

              <option value="Autre"> Autre </option>

            </select>
        </div>

        <div>
            <label for="Code Mission">Code Mission</label>
            <input type="text" v-model="mission.codeMission" :disabled ="flagDisabledCodeMission" >
        </div>


        <div>
            <label for="nom de mission spéciale (optionnel)">Nom de mission spéciale (optionnel)</label>
            <input type="text" v-model="mission.nameMissionSpecifique" :disabled ="flagDisabledMissionSpecifique">
        </div>

        <div v-if="flagAutreMission == true">
            <label for="Code Mission">Auter mission</label>
            <input type="text" v-model="mission.typeMission" :disabled ="flagDisabledAutreMission">
        </div>

        <div v-if="flagAutreMission == true">
            <label for="Numero d'affaire"></label>
            <input type="hidden" v-model="mission.numAffaire" >
        </div>

        <div>
            <label for="Unite">Unité</label>
            <input type="text" v-model="mission.unite" :disabled ="flagDisabledUnite">
        </div>

        <div>
          <label for="Prix HT">Prix HT</label>
          <input type="number" v-model="mission.prix" @input="calculePrix" :disabled="flagDisabledPrix">
        </div>

        <div>
            <label for="Quantité">Quantité</label>
            <input type="text" v-model="mission.quantite" @input="calculeQuantite" :disabled ="flagDisabledQuantite">
        </div>

        <div>
          <label for="Taux de remise">Taux de remise</label>
          <input type="number" v-model="mission.remise" @input="calculeRemise" :disabled ="flagDisabledRemise">
        </div>

        <div>
          <label for="Prix Total HT">Prix Total HT</label>
          <input type="number" v-model="prixTotal" disabled>
        </div>

        <div>
          <label for="Devis">Devis</label>
            <select v-model="mission.devis" :disabled ="flagDisabledDevis">
                <option v-for="devis in ArrayDevis" :key="devis.code" :value="devis.code" > {{ devis.name }}</option>
            </select>
        </div>


        <div class="mission-simple-equipement">
          <div>
            <input type="radio" value="simple" v-model="pickedTypeMission" @input="changeMissionSimple"/>
            <label for="one">Mission simple</label>
          </div>
          <div>
            <input type="radio" value="equipement" v-model="pickedTypeMission" @input="changeMissionSimple" />
            <label for="two">Mission avec des Équipement</label>
          </div>
        </div>

        <div>
            <label for="Numero d'affaire"></label>
            <input type="hidden" v-model="mission.numAffaire" >
        </div>


        <div class="equipement" v-for="eq in countEquipement" :key="eq - 1">

            <div style="width: 40%;" v-show="flagPickeTypeMission">
              <label for="Équipement">Équipement</label>
              <input type="text" v-model="mission.equipement[eq - 1]" :disabled="flagDisabledEquipement">
            </div>

            <div style="width: 10%;" v-show="flagPickeTypeMission">
              <label for="Prix">Prix</label>
              <input type="number" v-model="mission.prixEquipement[eq - 1]" :disabled ="flagDisabledEquipement" @input="calculePrixEquipement">
            </div>

            <div style="width: 5%;" v-show="flagPickeTypeMission">
              <label for="Quantite">Quantite</label>
              <input type="number" v-model="mission.quantiteEquipement[eq - 1]" :disabled="flagDisabledEquipement" @input="calculeQuantiteEquipement(eq)">
            </div>

            <div style="width: 10%;" v-show="flagPickeTypeMission">
              <label for="Prix">Taux de Remise</label>
              <input type="Number" v-model="mission.tauxRemiseEquipement[eq - 1]" :disabled ="flagDisabledEquipement" @input="calculeRemiseEquipement(eq)">
            </div>

            <div style="width: 10%;" v-show="flagPickeTypeMission">
              <label for="Prix">Prix Finale</label>
              <input type="Number" v-model="mission.prixFinaleEquipement[eq - 1]" :disabled ="flagDisabledEquipement">
            </div>

            <div style="width: 10%;" v-show="flagPickeTypeMission">
              <label for="Unite">Unite</label>
              <input type="text" v-model="mission.uniteEquipement[eq - 1]" :disabled="flagDisabledEquipement">
            </div>

            <div style="width:30%" v-show="flagPickeTypeMission">
              <label for="Numéro de rapport attendu correspond">Numéro de rapport attendu correspond</label>
              <input type="number" v-model="mission.qte[eq - 1]" :disabled ="flagDisabledEquipement">
            </div>

            
            <div style="width:30%" v-show="!flagPickeTypeMission">
              <label for="Numéro de rapport attendu général">Numéro de rapport attendu général</label>
              <input type="number" v-model="mission.qte[eq - 1]">
            </div>

        </div>


        <h4 v-show="flagPickeTypeMission" >Équipement Détails :</h4>


        <!-- Start faKe template html for UI UX -->
        <div v-show="flagPickeTypeMission">
          <label for="interlocuteurId"></label>
          <input type="hidden" >
        </div>

        <div v-show="flagPickeTypeMission">
          <label for="interlocuteurId"></label>
          <input type="hidden">
        </div>

        <div v-show="flagPickeTypeMission">
          <label for="interlocuteurId"></label>
          <input type="hidden">
        </div>
        <!-- Fin faKe template html for UI UX -->

        
        <div style="width :30%;" v-show="flagPickeTypeMission && flagButtonAjouterEquipement">
          <label for="Ajouter">Augmenter équipement</label>
          <button class="ajouter" @click="ajouteEquipement" style="opacity: 0.6;"><i class="fa-solid fa-plus"></i> Ajouter</button>
        </div>

        <div style="width :30%;" v-show="flagPickeTypeMission && flagButtonAnnulerEquipement">
          <label for="Annuler">Diminuer équipement</label>
          <button class="annuler" @click="annulerEquipement" style="opacity: 0.6;"><i class="fa-solid fa-xmark"></i>  Annuler</button>
        </div>



        <div>
          <label for="interlocuteurId"></label>
          <input type="hidden" v-model="interlocuteurId">
        </div>

        <div>
          <label for="clientId"></label>
          <input type="hidden" v-model="clientId">
        </div>

        <div style="width :30%;">
          <button class="valider" @click="valideMission(count)"><i class="fa-solid fa-circle-check"></i>Valider</button>
        </div>

        <div style="width :30%;">
          <button class="annuler"  @click="annuleFacture(count)"><i class="fa-solid fa-circle-xmark"></i>Supprimer</button>
        </div>

        <div style="width :30%;">
          <button class="ajouter" @click="ajouteFacture"><i class="fa-solid fa-circle-plus"></i>Ajouter</button>
        </div>


    </div>

    <div class="form" v-if="flagMession == false">
        <div style="width :30%;">
          <label for="Aperçu">Cliquez Pour Aperçevoir Cette Mission</label>
          <button @click="apercu(missionId)" class="apercue"><i class="fa-solid fa-eye"></i> Aperçevoir La Mission</button>
        </div>
    </div>

  </div>
</template>

<script>
import Service from "../../../../Service";
export default {

    props: {
      interlocuteurId: String,
      clientId: String,
      numAffaire : String,
      count: Number,
      countFacture : Number,
    },

      data() {

        return {
            countValideMissions : 0,
            flagButtonAjouterEquipement : true,
            flagButtonAnnulerEquipement : true,
            flagPickeTypeMission : false,
            pickedTypeMission : "simple",
            countRapport : null,
            file : null,
            countMission : 0,
            countEquipement : 1,
            countQuantiteEquipement : 1,
            missionId : null,
            flagMession : true,
            flagAutreMission: false,
            prixTotal : null,
            mission: {
                  typeMission : "",
                  typeRapport : null,
                  categorie : null,
                  codeMission: null,
                  equipement: [],
                  qte : [],
                  quantiteEquipement  : [],
                  uniteEquipement : [],
                  prixEquipement  : [],
                  tauxRemiseEquipement  : [],
                  prixFinaleEquipement  : [],
                  prix : null,
                  devis:null,
                  autreMission: null,
                  numAffaire: null,
                  remise : null,
                  unite : null,
                  quantite  : null,
                  nameMissionSpecifique : null,
                  statusEquipement : false
            },

            flagDisabledCodeMission : true,
            flagDisabledAutreMission : false,
            flagDisabledUnite : false,
            flagDisabledMissionSpecifique : false,
            flagDisabledQuantite : false,
            flagDisabledEquipement : false,
            flagDisabledDevis : false,
            flagDisabledRemise : false,
            flagDisabledPrix : false,
            flagDisabledMissions : false,

            arrayMission: [],

            ArrayTypeMissionIE : [
                  { prev : "ELEC-VRI" , name : "Vérification réglementaire initial d’installation électrique", type	 : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRP" , name : "Vérification réglementaire périodique d’installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRPPV" , name : "Vérification réglementaire périodique d’installation photovoltaique", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VRPPP" , name : "Vérification réglementaire périodique d’installation parafoudre - paratonnere",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VLRVR" , name : "Vérification de levée des réserves suite vérification réglementaire d’installation électrique", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VTH" , name : "Vérification d'installation électrique par thermographie infrarouge sans Q19", type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VTHQ19" , name : "Vérification d'installation électrique par thermographie infrarouge avec Q19",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-VAR" , name : "Vérification d'installation électrique par analyseur de réseau",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-D" , name : "Diagnostique d’une installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "ELEC-AT" , name : "Assistance technique installation électrique",type : "Installations électriques"  ,categorie : "Contrôle réglementations installations techniques" }
                ],

            ArrayTypeMissionAAL : [
                  { prev : "LEV-VRMRS" , name : "Vérification réglementaire de mise ou remise en service d’appareils et accessoires de levage", type : "Appareils et accessoires de levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-VRP" , name : "Vérification réglementaire périodique d’appareils et accessoires de levage", type : "Appareils et accessoires de levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-VLRVR" , name : "Vérification de levée des réserves suite Vérification réglementaire d’appareils et accessoires de levage", type : "Appareils et accessoires de levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "LEV-D" , name : "Diagnostique d’appareils et accessoires de levage", type : "Appareils et accessoires de levage"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-QN" , name : "Vérification d'un équipement de travail - Quais niveleurs", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-PP" , name : "Vérification d'un équipement de travail - Portes et portail", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-EPI" , name : "Vérification d'un équipement de travail - Equipement de Protection Individuel", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "VET-RY" , name : "Vérification d'un équipement de travail - Rayonnage", type : "Equipement de travail"  ,categorie : "Contrôle réglementations installations techniques" }
                ],

            ArrayTypeMissionID : [
               { prev : "ASC-VDT" , name : "Vérification du dossier technique d’une installation d’ascenseur ou monte-charge accompagné", type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC- STI" , name : "Suivi des travaux d’installation d’ascenseur ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VMRS" , name : "Vérification réglementaire de mise ou remise en service d’une installation d’ascenseur ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VRP" , name : "Vérification réglementaire périodique d'une installation d’ascenseur ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-VLRVR" , name : "Vérification de levé de reserves suite vérification réglementaire périodique d’une installation d’ascenseurs ou monte-charge accompagné",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-AST" , name : "Assistance technique ascenseur",type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
               { prev : "ASC-D" , name : "Diagnostique ascenseur", type : "Installation d’ascenseur"  ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionDI : [
                  { prev : "INC- VTSSI" , name : "Vérification  triennale d'un Système de Sécurité Incendie" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VPSSI" , name : "Vérification  périodique d'un Système de Sécurité Incendie" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VSDF" , name : "Vérification  du système de désenfumage" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VMSS" , name : "Vérification  des moyens de secours simples" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- VGC" , name : "Vérification  des appareils de cuisson et de remise en températures" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- AUD" , name : "Audit de mise ou remise en conformité réglementaire des dispositifs incendie" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- DDI" , name : "Diagnostique des dispositifs incendie" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "INC- AT" , name : "Assistance technique dispositif incendie" ,type : "Dispositifs incendie" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionAPGV : [
                  { prev : "PRS-VQRQ" , name : "Vérification  d'un appreil à pression gas/vapeur pour qualification / requalification " ,type : "Appareils à pression gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "PRS-VRP" , name : "Vérification  réglementaire d'un appreil à pression gas/vapeur" ,type : "Appareils à pression gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "PRS-D" , name : "Diagnostique d'un appreil à pression gas/vapeur" ,type : "Appareils à pression gaz et vapeur" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionCMEC : [
                  { prev : "MAC-VGPT" , name : "Vérification Générale Périodique Trimestriel machinee" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-VGPA" , name : "Vérification Générale Périodique Annuelle machiner" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-VCCE" , name : "Vérification de la conformité CE machine" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-AR" , name : "Analyses des risque d'une machine" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
                  { prev : "MAC-D" , name : "Diagnostique d'un appreil à pression gas/vapeur" ,type : "Vérification machines et engins de chantiers" ,categorie : "Contrôle réglementations installations techniques" },
            ],

            ArrayTypeMissionCRT : [
                  { prev : "BRUIT-VDBD" , name : "Vérification des doses de bruits absorbées par les travailleurs par dosimétrie" , type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "BRUIT-VDBCT" , name : "Vérification des doses de bruits absorbées par les travailleurs code du travail" , type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "BRUIT-CRT" , name : "Élaboration de la cartographie bruit" ,type : "Vérification des risques au travail" ,categorie : "Contrôle des risques travailleur" },
                  { prev : "ECRM-MTE" , name : "Mesures des taux d'éclairement au poste du travail" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "AIR-MQA" , name : "Mesure de la qualité de l'air intérieur" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "EAU-POT" , name : "Analyse de la potabilité de l’eau" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
                  { prev : "EAU-LEG" , name : "Analyse des légionnelles" , type : "Vérification des risques au travail" , categorie : "Contrôle des risques travailleur" },
            ],

            ArrayTypeMissionENV : [
                  { prev : "ENVIR-ARL" , name : "Analyse des rejets liquides" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-ARAT" , name : "Analyse des rejets atmosphérique" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-MNS" , name : "Mesures se la nuisance sonore" ,type : "Environnement" ,categorie : "Contrôle environnementaux" },
                  { prev : "ENVIR-AQA" , name : "Analyse de la qualité de l'air environnant" ,type : "Environnement" ,categorie : "Contrôle environnementaux" }
            ],

            ArrayTypeMissionI : [

                  { prev : "EVAC-INC" , name : "Evacuation : guide-files et serre-files" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "EPI-INC" , name : "Equipier Première Intervention" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "ESI-INC" , name : "Equipier Seconde Intervention" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "SST-INC" , name : "Sauveteur Secouriste du Travail" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "PF-INC" , name : "Permis feu" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },
                  { prev : "SSI1-INC" , name : "Exploitation d’un Système de Sécurité Incendie" ,type : "Formations réglementaires incendie" ,categorie : "Formation reglementaires" },

            ],

            ArrayTypeMissionE : [

              { prev : "HAB-001" , name : "Préparation à l’habilitation électrique, personnel non électricien ind. B0-H0-H0V" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-002" , name : "Préparation à l’habilitation électrique, personnel non électricien ind B0-H0-H0V" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-003" , name : "Préparation à l’habilitation électrique, personnel électricien Basse tension" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-004" , name : "Préparation à l’habilitation électrique, personnel électricien Basse et/ou haute tension (HTA)" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-005" , name : "Préparation à l’habilitation électrique, personnel effectuant des verifications, des mesurages, des manoeuvres es basse et/ou haute tesnsion - Ind. BE-HE verification, mesurage et manoeuvre" ,type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" },
              { prev : "HAB-006" , name : "Recyclage" , type : "Formations réglementaires electricité" ,categorie : "Formation reglementaires" }

            ],

          ArrayDevis : [

            {"name":"MAD","code":"MAD"},
            {"name":"Euro","code":"EUR"},
            {"name":"US Dollar","code":"USD"}

        ],
        };

  },

  watch: {
    numAffaire: {
      handler(newValue) {
        console.log(newValue)
      }
    },
  },
  methods : {

      annuleFacture() {
        this.$emit("annuleFacture");
      },

      ajouteFacture() {
        this.$emit("ajouteFacture");
      },


      calculePrixEquipement() {
        var somme = 0;
        for(var i = 0; i < this.mission.equipement.length; i++) {
            somme += Number(this.mission.prixEquipement[i]);
        }
        this.mission.prix = somme;
      },

      calculeQuantiteEquipement(index) {
        this.mission.prixFinaleEquipement[index - 1] = parseInt(this.mission.quantiteEquipement[index - 1]) * parseFloat(this.mission.prixEquipement[index - 1]).toFixed(2);
        var somme = 0;
        for(var i = 0; i < this.mission.equipement.length; i++) {
            somme += Number(this.mission.prixEquipement[i] * this.mission.quantiteEquipement[i]);
        }
        
        this.mission.prix = somme;
      },

      calculeRemiseEquipement(index) {
        const prix = parseInt(this.mission.quantiteEquipement[index - 1]) * parseFloat(this.mission.prixEquipement[index - 1]).toFixed(2);
        this.mission.prixFinaleEquipement[index - 1] = parseFloat(prix - ((prix * this.mission.tauxRemiseEquipement[index - 1]) / 100)).toFixed(2);
        var somme = 0;
        for(var i = 0; i < this.mission.equipement.length; i++) {
            somme += Number(this.mission.prixFinaleEquipement[i]);
        }
        this.prixTotal = somme;
      },
    
      changeMissionSimple($event) {

        if($event.target.value == "simple") {
           this.flagPickeTypeMission = false;
           this.flagDisabledUnite = false;
           this.flagDisabledPrix = false;
           this.flagDisabledQuantite = false; 
           this.flagDisabledRemise = false;
        }

        if($event.target.value == "equipement") {
            this.flagPickeTypeMission = true;
            this.flagDisabledCodeMission = true;
            this.flagDisabledAutreMission = false ;
            this.flagDisabledUnite = true;
            this.flagDisabledMissionSpecifique = false;
            this.flagDisabledQuantite = true; 
            this.flagDisabledEquipement = false;
            this.flagDisabledDevis = false;
            this.flagDisabledRemise = true;
            this.flagDisabledPrix = true;
            this.flagDisabledMissions = false;
            this.mission.statusEquipement = true;
        }
        
      },

      calculeQuantite($event) {
        this.prixTotal = parseFloat(parseFloat(this.mission.prix).toFixed(2) * $event.target.value).toFixed(2);
      },

      calculeRemise($event) {
          if($event.target.value == 0 || $event.target.value >= 100) {
            this.prixTotal = parseFloat(this.mission.prix).toFixed(2) * this.mission.quantite;
          } else {
            const prixWithquantite = parseFloat(this.mission.prix).toFixed(2) * this.mission.quantite;
            this.prixTotal = parseFloat(prixWithquantite - ((prixWithquantite * $event.target.value) / 100)).toFixed(2);
          }
      },

      calculePrix($event) { 
        this.prixTotal = parseFloat($event.target.value - ((this.mission.remise * $event.target.value) / 100)).toFixed(2);
      },

      valideEquipement() {

        const resultQte = this.mission.qte.filter(element => {
           return element !== null;
        });

        this.mission.qte = [];

        if(resultQte.length == 0) {
          resultQte[0] = 1;
          this.mission.qte = resultQte;
        } else {
          this.mission.qte = resultQte;
        }

        const resultEquipement = this.mission.equipement.filter(element => {
            return element !== null;
        });

        this.mission.equipement = [];

        if(resultEquipement.length == 0) {
          resultEquipement[0] = " ";
          this.mission.equipement = resultEquipement;
        } else {
          this.mission.equipement = resultEquipement;
        }



        let flagTypeMission = false;
        flagTypeMission = (this.mission.typeMission != null && this.mission.typeMission != undefined && this.mission.typeMission != "");
        if(!flagTypeMission) {
          alert("Type de mission vide");
        }

        let flagCodeMission = false;
        flagCodeMission = (this.mission.codeMission != null && this.mission.codeMission != "" && this.mission.codeMission != undefined);
        if(!flagCodeMission) {
               alert("Code de mission vide");
        }

        let flagDevis = false;
        flagDevis = (this.mission.devis != null && this.mission.devis != "" && this.mission.devis != undefined);
        if(!flagDevis) {
               alert("Devis vide");
        }

        // function for checked not  empty or No Equipement
        const checkedNull = (tab) => {
          var arr = new Array();
              for(let i = 0; i < tab.length; i++) {
                 if(tab[i] != null && tab[i] != undefined && tab[i] != "") {
                    arr.push(true);
                 } else {
                  arr.push(false);
                 }
              }
          // check all values true in array
          let checker = a => a.every(v => v === true);
          return checker(arr);

        }


        let flagEquipement = false;
        flagEquipement = checkedNull(this.mission.equipement);
        console.log(flagEquipement)
        if(!flagEquipement) {
              alert("Nom d'equipement vide");
        }

        let flagPrix = false;
        flagPrix = checkedNull(this.mission.prixEquipement);
        console.log(flagPrix)
        if(!flagPrix) {
              alert("Prix d'equipement vide");
        }

        let flagQuantiteEquipement = false;
        flagQuantiteEquipement = checkedNull(this.mission.quantiteEquipement);
        console.log(flagQuantiteEquipement)
        if(!flagQuantiteEquipement) {
              alert("Quantite d'equipement vide");
        }


        let flagRemise = false;
        flagRemise = checkedNull(this.mission.tauxRemiseEquipement);
        console.log(flagRemise)
        if(!flagRemise) {
              alert("Remise d'equipement vide");
        }

        let flagPrixFinaleEquipement = false;
        flagPrixFinaleEquipement = checkedNull(this.mission.prixFinaleEquipement);
        console.log(flagPrixFinaleEquipement)
        if(!flagPrixFinaleEquipement) {
              alert("Prix Final d'equipement vide");
        }

        let flagUniteEquipement = false;
        flagUniteEquipement = checkedNull(this.mission.uniteEquipement);
        console.log(flagUniteEquipement)
        if(!flagUniteEquipement) {
              alert("Unite d'equipement vide");
        }

        let flagQte = false;
        flagQte = checkedNull(this.mission.qte);
        console.log(flagQte)
        if(!flagQte) {
              alert("Numero de rapport d'equipement vide");
        }



        if(flagTypeMission && flagCodeMission && flagDevis && flagEquipement && flagPrix && flagQuantiteEquipement && flagRemise && flagPrixFinaleEquipement && flagUniteEquipement && flagQte) {
              this.flagDisabledCodeMission = true;
              this.flagDisabledAutreMission = true ;
              this.flagDisabledUnite = true;
              this.flagDisabledMissionSpecifique = true;
              this.flagDisabledQuantite = true; 
              this.flagDisabledEquipement = true;
              this.flagDisabledDevis = true;
              this.flagDisabledRemise = true;
              this.flagDisabledPrix = true;
              this.flagDisabledMissions = true;
        }

      },

      ajouteEquipement() {
            this.countEquipement++ ;
            this.countQuantiteEquipement++ ;
            this.mission.equipement.push(null);
            this.mission.prixEquipement.push(null);
            this.mission.prixFinaleEquipement.push(null);
            this.mission.qte.push(null);
            this.mission.quantiteEquipement.push(null);
            this.mission.tauxRemiseEquipement.push(null);
            this.mission.uniteEquipement.push(null);
      },

      annulerEquipement() {

        if(this.countEquipement != 1 || this.countEquipement > 1) {

            this.countEquipement-- ;
            this.countQuantiteEquipement-- ;

            const coundDelete = (this.mission.equipement.length - 1);
            
            this.mission.equipement[coundDelete] = "";
            this.mission.equipement.splice(coundDelete, 1);

            this.mission.prixEquipement[coundDelete] = "";
            this.mission.prixEquipement.splice(coundDelete, 1);

            this.mission.prixFinaleEquipement[coundDelete] = "";
            this.mission.prixFinaleEquipement.splice(coundDelete, 1);

            this.mission.qte[coundDelete] = "";
            this.mission.qte.splice(coundDelete, 1);

            this.mission.quantiteEquipement[coundDelete] = "";
            this.mission.quantiteEquipement.splice(coundDelete, 1);

            this.mission.tauxRemiseEquipement[coundDelete] = "";
            this.mission.tauxRemiseEquipement.splice(coundDelete, 1);

            this.mission.uniteEquipement[coundDelete] = "";
            this.mission.uniteEquipement.splice(coundDelete, 1);

            this.mission.prix = 0
            this.prixTotal = 0;

            this.mission.prixEquipement.forEach((el, index) => {

              let prixBeforRemise = parseFloat(el * this.mission.quantiteEquipement[index]);
              let prixAfterRemise = 0;
      
              if(this.mission.tauxRemiseEquipement[index] == "0" || this.mission.tauxRemiseEquipement[index] == 0) {
                prixAfterRemise = parseFloat(prixBeforRemise - ((1 * prixBeforRemise) / 100));
              } else {
                prixAfterRemise = parseFloat(prixBeforRemise - ((this.mission.tauxRemiseEquipement[index] * prixBeforRemise) / 100));
              }

              this.mission.prixFinaleEquipement[index] = prixAfterRemise;

              this.mission.prix = this.mission.prix + prixBeforRemise;
              this.prixTotal = this.prixTotal + prixAfterRemise;

            });


        }


        if(this.countEquipement == 1) {
          this.flagPickeTypeMission = false;
          this.pickedTypeMission = "simple";
          this.mission.typeMission = "";
          this.mission.typeRapport = null;
          this.mission.categorie = null;
          this.mission.codeMission = null;
          this.mission.equipement = [];
          this.mission.qte = [];
          this.mission.quantiteEquipement = [];
          this.mission.uniteEquipement = [];
          this.mission.prixEquipement = [];
          this.mission.tauxRemiseEquipement = [];
          this.mission.prixFinaleEquipement = [];
          this.mission.prix = null;
          this.mission.devis = null;
          this.mission.autreMission = null;
          this.mission.numAffaire = null;
          this.mission.remise = null;
          this.mission.unite = null;
          this.mission.quantite = null;
          this.mission.nameMissionSpecifique = null;
          this.mission.statusEquipement = null;


           this.flagPickeTypeMission = false;
           this.flagDisabledUnite = false;
           this.flagDisabledPrix = false;
           this.flagDisabledQuantite = false; 
           this.flagDisabledRemise = false;


        }

      },

      // select mission
      selectMission(event) {

            const allvalue = event.target.value.split("_", 4);

            if(allvalue[0] == "Autre")
            {
              this.flagDisabledAutreMission = true;
              this.flagAutreMission = true;
              this.flagDisabledCodeMission = false;
              this.mission.codeMission = null;
              this.mission.categorie = "Autre";
              this.mission.typeRapport = "Autre";

            } else {
              this.flagAutreMission = false;
              this.flagDisabledCodeMission = true;
              this.mission.codeMission = allvalue[0];
              this.mission.categorie = allvalue[1];
              this.mission.typeRapport = allvalue[3];
            }

      },

      valideMission(count) {

        // merge all array for search value type mission
        const allArrayTypeMission = this.ArrayTypeMissionIE.concat(this.ArrayTypeMissionAAL, this.ArrayTypeMissionID, this.ArrayTypeMissionDI, this.ArrayTypeMissionAPGV, this.ArrayTypeMissionCMEC, this.ArrayTypeMissionCRT, this.ArrayTypeMissionENV, this.ArrayTypeMissionI, this.ArrayTypeMissionE);

        // search value by type mission (previation)
        let typeMission = allArrayTypeMission.find(el => el.prev === this.mission.codeMission);

        // check is exist name or No
        let flagCheckExistName = false;
        if(typeMission == undefined) {
          flagCheckExistName = true;
          typeMission = {
            name : "Autre"
          };
        }

        // set data with Good form
        const obj = {
                   typeMission: flagCheckExistName == true ? "Autre" : typeMission.name,
                   codeMission : this.mission.codeMission,
                   equipement : this.mission.equipement,
                   qte : this.mission.qte,
                   quantiteEquipement  : this.mission.quantiteEquipement,
                   uniteEquipement : this.mission.uniteEquipement,
                   prixEquipement  : this.mission.prixEquipement,
                   tauxRemiseEquipement  : this.mission.tauxRemiseEquipement,
                   prixFinaleEquipement  : this.mission.prixFinaleEquipement,
                   prix : this.prixTotal,
                   devis : this.mission.devis,
                   interlocuteurId : this.interlocuteurId,
                   clientId : this.clientId,
                   numeroAffaire : this.numAffaire,
                   status : "invalid",
                   statusWithAffaire : false,
                   typeRapport :  this.mission.typeRapport,
                   categorie : this.mission.categorie,
                   remise : this.mission.remise,
                   unite : this.mission.unite,
                   quantite : this.mission.quantite,
                   nameMissionSpecifique : this.mission.nameMissionSpecifique,
                   statusEquipement : this.mission.statusEquipement
          };


          if(this.pickedTypeMission == "simple") {

            const flagTypeMission = (obj.typeMission == null || obj.typeMission == "" || obj.typeMission == undefined);
            if(flagTypeMission) {
              alert("Type de mission vide");
            }

            const flagCodeMission = (obj.codeMission == null || obj.codeMission == "" || obj.codeMission == undefined);
            if(flagCodeMission) {
              alert("Code de mission vide");
            }

            const flagUnite = (obj.unite == null || obj.unite == "" || obj.unite == undefined);
            if(flagUnite) {
              alert("Unite vide");
            }

            const flagMission = (this.mission.prix == null || this.mission.prix == "" || this.mission.prix == undefined)
            if(flagMission) {
              alert("Prix HT vide");
            }

            const flagQuantite = (obj.quantite == null || obj.quantite == "" || obj.quantite == undefined);
            if(flagQuantite) {
              alert("Quantite vide");
            }

            const flagRemise = (obj.remise == null || obj.remise == "" || obj.remise == undefined);
            if(flagRemise) {
              alert("Taux de Remise vide");
            }

            const flagPrix = (obj.prix == null || obj.prix == "" || obj.prix == undefined);
            if(flagPrix) {
              alert("Prix Total HT vide");
            }

            const flagDevis = (obj.devis == null || obj.devis == "" || obj.devis == undefined);
            if(flagDevis) {
              alert("Devis vide");
            }

            const flagNumRapport = obj.qte[0] == null || obj.qte[0] == "" || obj.qte[0] == undefined;
            if(flagNumRapport) {
              alert("Numéro de rapport attendu général vide");
            }

            if(!flagTypeMission && !flagCodeMission && !flagUnite && !flagMission && !flagQuantite && !flagRemise && !flagPrix && !flagDevis && !flagNumRapport) {

                if(this.flagPickeTypeMission == false) {
                      this.flagDisabledCodeMission = true;
                      this.flagDisabledAutreMission = true ;
                      this.flagDisabledUnite = true;
                      this.flagDisabledMissionSpecifique = true;
                      this.flagDisabledQuantite = true; 
                      this.flagDisabledEquipement = true;
                      this.flagDisabledDevis = true;
                      this.flagDisabledRemise = true;
                      this.flagDisabledPrix = true;
                      this.flagDisabledMissions = true;
                }
                // sel all value in array
                this.arrayMission.push(obj);
                // create mission 
                Service.createMission(this.arrayMission)
                .then((result) => {
                    // hiddden form mission
                    this.flagMession = false;
                    this.missionId = result.data.data._id;
                    // var pointeur for delete mission and decrementer ref rapport OK
                    this.countRapport = result.data.data.qte.reduce(function(a, b){
                        return parseInt(a) + parseInt(b);
                      }, 0);
                    // emit
                    this.countValideMissions++
                    this.$emit('addCheckedMission', true, count);

                })
                .catch((err) => {
                    console.log(err)
                });

            }

          }

          if(this.pickedTypeMission == "equipement") {
              this.flagButtonAjouterEquipement = false;
              this.flagButtonAnnulerEquipement = false;
              this.valideEquipement();
              // sel all value in array
              this.arrayMission.push(obj);
              // create mission 
                Service.createMission(this.arrayMission)
                .then((result) => {
                    // hiddden form mission
                    this.flagMession = false;
                    this.missionId = result.data.data._id;
                    // var pointeur for delete mission and decrementer ref rapport OK
                    this.countRapport = result.data.data.qte.reduce(function(a, b){
                        return parseInt(a) + parseInt(b);
                      }, 0);
                    // emit
                    this.$emit('addCheckedMission', true, count)

                })
                .catch((err) => {
                    console.log(err)
                });

          }


      },

      // Apercu la misssion pour modifier et suprrimer a la BD pour evite probleme stockage
      
      apercu(missionId) {

        Service.deleteMission(missionId, this.countRapport)
          .then(() => {
              // not hidden form mission
              this.flagMession= true
              this.$emit('deleteCheckedMission', true)
              this.flagDisabledQuantite = !this.flagDisabledQuantite;

              // disable inputs
              this.flagDisabledEquipement = false;
              this.flagDisabledDevis = false;
              this.flagDisabledRemise = false;
              this.flagDisabledUnite = false;
              this.flagDisabledQuantite = false;
              this.flagDisabledPrix = false;
              this.flagDisabledMissions = false;

              //disable content
              this.mission.typeMission = null;
              this.mission.typeMission = null;
              this.mission.typeRapport =  null;
              this.mission.categorie = null;
              this.mission.codeMission = null;
              this.mission.equipement = [];
              this.mission.qte = [];
              this.mission.prix = 0;
              this.mission.devis = null;
              this.mission.autreMission = null;
              this.mission.numAffaire = null;
              this.mission.remise = 0;
              this.mission.unite = null;
              this.mission.quantite = 1;
              this.prixTotal = 0;

          })
          .catch((err) => {
              console.log(err)
          });
      }



    },
    created() {
      // set Data in arra quantite and equipment for
        this.mission.equipement[0] = null;
        this.mission.numAffaire = this.numAffaire;
        this.mission.qte[0] = null;
    }

}


</script>

<style scoped>

.form {
  padding:10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

}

.form div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}


.formCreation div label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  color :#494949;
}
.formCreation div input {
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid #243064;
  padding:5px;
}.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}

.formCreation div input:focus-within {
  outline: 1px solid #cf1f21 ;
  border:0;

}


.formCreation div select {
  height: 40px;
}

hr {
  width: 100%;
  background-color: rgb(190, 190, 190);
  height: 5px;
  margin:10px 0;
}

.ajouter {
  background-color: rgb(15, 82, 224);
  color: white;
  border: 0 !important;
  padding:15px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.ajouter:hover{
  background-color: rgb(4, 37, 109);
  border-radius: 30px;
  letter-spacing: 2px;
}

.valider{
  background-color: rgb(33, 146, 33);
  color: white;
  border: 0 !important;
  cursor: pointer;
  padding:15px;
  transition: 0.3s;
  font-size: 16px;
}

.valider:hover{
  background-color: rgb(17, 94, 17);
  border-radius: 30px;
  letter-spacing: 2px;

}

.annuler {
  background-color: rgb(184, 0, 0);
  color: white;
  border: 0 !important;
  padding:15px;
  transition: 0.3s;
  font-size: 16px;
  cursor: pointer;
}

.annuler:hover{
  background-color: rgb(110, 13, 13);
  border-radius: 30px;
  letter-spacing: 2px;

}


.apercue {
  background-color: rgb(14, 10, 236);
  color: rgb(255, 255, 255);
  width: 100%;
  border: 0px !important;
  outline: 0px;
  cursor: pointer;
  padding:15px;
  transition: 0.4s;
}

.apercue:hover{
  background-color: rgb(22, 9, 139);
  letter-spacing: 2px;

}

.enregistrer {
  background-color: green;
  color: white;
  border: 0px;
  outline: 0px;
  cursor: pointer;
}

#app > div > div > div.menu-content > div.content > div > div:nth-child(7) > div.all > div > div.equipement {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}
h4{
  background-color: rgb(75, 75, 75);
  color:rgb(255, 255, 255);
  padding:5px 10px;

  margin: 5px;
  width: 30%;
}

#app > div > div > div.menu-content > div.content > div > div:nth-child(7) > div.all > div > div.equipement > div:nth-child(1) {
  width: 60%;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
#app > div > div > div.menu-content > div.content > div > div:nth-child(7) > div.all > div > div.equipement > div:nth-child(1) > input[type=text] {
  width: 100%;
}

#app > div > div > div.menu-content > div.content > div > div:nth-child(7) > div.all > div > div.equipement > div:nth-child(2) {
  width: 10%;
  padding: 0;
  margin: 0;
}
#app > div > div > div.menu-content > div.content > div > div:nth-child(7) > div.all > div > div.equipement > div:nth-child(2) > input[type=text] {
  width: 50%;
}

input[type=submit]:hover{
background-color: #ffdf29;
}

input[type=submit]{
font-size: 18px;
text-transform: capitalize;
}

.mission-simple-equipement > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mission-simple-equipement > div > input,  .mission-simple-equipement > div > label {
    margin: 5px;
    padding: 5px;
}
</style>